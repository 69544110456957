<template>
  <div></div>
</template>
<script>
import { getReportPara } from "@/api/threeYears";
import { getPageStyle } from "@/api/api";

export default {
  name: "Detect",
  mounted() {
    //来之后台管理 先塞一下token
    if (this.$route.query.fromHouTai == "houtai") {
      localStorage.setItem("authorization", this.$route.query.token);
    }
    //
    this.detect();
  },
  methods: {
    async detect() {
      let parentRouteNameSurfix = "";
      if (this.$route.matched[0] && this.$route.matched[0].name == "share") {
        parentRouteNameSurfix = "-" + this.$route.matched[0].name;
      }
      //来自管理后台
      // if (this.$route.query.fromAdmin) {
      //   sessionStorage.setItem("fromAdmin", 1)
      // }
      //
      // if (isMobile()) {
      //   sessionStorage.setItem("device", 'mobile')
      // } else {
      //   sessionStorage.setItem("device", 'pc')
      // }

      const pageStyleRes = await getPageStyle({ programId: this.$route.params.uuid });
      const reportParaRes = await getReportPara({ programId: this.$route.params.uuid });
      // console.log(pageStyleRes.data);
      if (pageStyleRes.data == "可视化+详情") {
        this.$router.push({
          path: `../result-head/${this.$route.params.uuid}`,
          query: {
            bankId: reportParaRes.data.bankid,
            zq: reportParaRes.data.zqId,
            uuid: reportParaRes.data.uuid,
            nsrsbh: reportParaRes.data.nsrsbh,
            name: reportParaRes.data.bankMc,
            ...this.$route.query,
          },
        });
      } else if (pageStyleRes.data == "三年版") {
        if (this.isMobileDevice) {
          this.$router.push({
            path: `../H5ThreeYearResult/${this.$route.params.uuid}`,
            query: {
              bankId: reportParaRes.data.bankId,
              zq: reportParaRes.data.zqId,
              zqLocation: reportParaRes.data.zqId,
              uuid: this.$route.params.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              taxNo: reportParaRes.data.invoiceNsrsbh,
              //name: scope.row.bankMc,
              version: reportParaRes.data.tag,
              companyName: reportParaRes.data.companyName,
              //desensitization: this.$route.query.desensitization
              ...this.$route.query,
            },
          });
        } else {
          this.$router.push({
            path: `../ThreeYearResult/${this.$route.params.uuid}`,
            query: {
              bankId: reportParaRes.data.bankId,
              zq: reportParaRes.data.zqId,
              zqLocation: reportParaRes.data.zqId,
              uuid: this.$route.params.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              taxNo: reportParaRes.data.invoiceNsrsbh,
              //name: scope.row.bankMc,
              version: reportParaRes.data.tag,
              companyName: reportParaRes.data.companyName,
              //desensitization: this.$route.query.desensitization
              ...this.$route.query,
            },
          });
        }
      } else if (pageStyleRes.data == "体验版") {
        this.$router.push({
          path: `../Result/Result/${this.$route.params.uuid}/tiyan`,
          query: {
            bankId: reportParaRes.data.bankid,
            zq: reportParaRes.data.zqId,
            uuid: reportParaRes.data.uuid,
            nsrsbh: reportParaRes.data.nsrsbh,
            name: reportParaRes.data.companyName,
            ...this.$route.query,
          },
        });
      } else if (pageStyleRes.data == "房地产版") {
        this.$router.push({
          path: `../Result/Result/${this.$route.params.uuid}`,
          query: {
            bankId: reportParaRes.data.bankid,
            zq: reportParaRes.data.zqId,
            uuid: reportParaRes.data.uuid,
            nsrsbh: reportParaRes.data.nsrsbh,
            name: reportParaRes.data.companyName,
            ...this.$route.query,
          },
        });
      } else if (pageStyleRes.data == "详情") {
        if (this.isMobileDevice) {
          this.$router.push({
            path: `../h5/Result/Result/${this.$route.params.uuid}`,
            query: {
              bankId: reportParaRes.data.bankid,
              zq: reportParaRes.data.zqId,
              uuid: reportParaRes.data.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              name: reportParaRes.data.companyName,
              ...this.$route.query,
            },
          });
        } else {
          this.$router.push({
            path: `../Result/${this.$route.params.uuid}`,
            query: {
              bankId: reportParaRes.data.bankid,
              zq: reportParaRes.data.zqId,
              uuid: reportParaRes.data.uuid,
              nsrsbh: reportParaRes.data.nsrsbh,
              name: reportParaRes.data.companyName,
              ...this.$route.query,
            },
          });
        }
      } else if (pageStyleRes.data == "老板看报告") {
        this.$router.push({
          path: `../boss-watch-report/${this.$route.params.uuid}`,
          query: {
            bankId: reportParaRes.data.bankid,
            zq: reportParaRes.data.zqId,
            uuid: reportParaRes.data.uuid,
            nsrsbh: reportParaRes.data.nsrsbh,
            name: reportParaRes.data.companyName,
            ...this.$route.query,
          },
        });
      } else {
        this.$alert(`报告样式,找不到对应的报告样式:${pageStyleRes.data}`);
      }
    },
  },
  computed: {
    isMobileDevice() {
      return window.innerWidth < 600;
    },
  },
};
</script>

<style lang="stylus" scoped></style>
